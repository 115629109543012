import React from "react";

import BannerWrapperCustomImg from "../../top_banner/banner_wrapper_custom_img";
import { useTranslation } from 'react-i18next';

const GeneralTerms = () => {
  const {t} = useTranslation();
  return (
    <div className={`conatiner font-sans`}>
      <BannerWrapperCustomImg
        largeBgImg={"https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png"}
        nonlargeBgImg={"https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png"}
      >
        <div className=" flex flex-col  justify-center items-center h-full py-20  mx-auto">
          <div class="font-bold text-3xl md:text-6xl text-center mx-w-xl">
           <h1>{t('general_conditions_of_use')}</h1>
          </div>
          <div class=" text-sm md:text-xl  font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0">
            {t('last_updated_on_may_2_2022')}
          </div>
        </div>
      </BannerWrapperCustomImg>

      <div className={`mx-auto w-11/12 lg:w-5/6 my-16`}>
        <div className="">
          <span className="font-bold">{`${t('about_us')}`}</span> : {t('the_company_dt_Education_sas')}
        </div>
        <div className="ml-8 my-8">
          <div className="font-bold">
            1. {t('general_provisions')}
          </div>
          <ol className="mt-4">
            <li className="ml-8">
              a. {t('these_tandc_describe_the_conditions')} (la « <span className="font-bold">{t('platform')}</span> »).
            </li>
            <li className="ml-8">
              b. {t('we_advise_you_to_read_these_general')}
            </li>
            <li className="ml-8">
              c. {t('by_using_our_site_you_fully')}
            </li>
            <li className="ml-8">
              d. {t('if_you_do_not_accept_the_general')}
            </li>
            <li className="ml-8">
              e. {t('each_of_the_clauses_of_these_general')}
            </li>
          </ol>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            2. {t('other_applicable_provisions')}
          </div>
          <ol className="mt-4">
            <li className="ml-8">
              a. {t('to_fully_access_our_services')}
            </li>
            <li className="ml-8">
              b. {t('the_processing_of_personal_data')}
            </li>
            <li className="ml-8">
              c. {t('access_our_platform_is_secure')} (<u>contact@mereos.eu</u>).
            </li>
            <li className="ml-8">
              d. {t('if_we_have_reason_to_believe')}
            </li>
          </ol>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            3. {t('site_changes')}
          </div>
          <ol className="mt-4">
            <li className="ml-8">
              a. {t('we_may_update_our_site')}
            </li>
            <li className="ml-8">
              b. {t('we_are_in_no_way_able')}
            </li>
            <li className="ml-8">
              c. {t('access_our_platform_is_secure')} (<u>contact@mereos.eu</u>).
            </li>
            <li className="ml-8">
              d. {t('if_we_have_reason_to_believe')}
            </li>
          </ol>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            4. {t('access_to_the_site')}
          </div>
          <ol className="mt-4">
            <li className="ml-8">
              a. {t('access_to_our_site_is_granted')}
            </li>
            <li className="ml-8">
              b. {t('it_is_your_responsibility_to_make')}
            </li>
            <li className="ml-8">
              c. {t('it_is_your_responsibility_to_ensure')}
            </li>
            <li className="ml-8">
              d. {t('our_site_is_aimed_at_people')}
            </li>
          </ol>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            5. {t('intellectual_property')}
          </div>
          <ol className="mt-4">
            <li className="ml-8">
              a. {t('all_intellectual_property_rights_relating')}
            </li>
            <li className="ml-8">
              b. {t('you_expressly_acknowledge')}
            </li>
            <li className="ml-8">
              c. {t('we_authorize_you_to_make_physical')}
            </li>
            <li className="ml-8">
              d. {t('you_agree_not_to_use_all')}
            </li>
            <li className="ml-8">
              e. {t('subject_to_these_general_conditions')}
            </li>
          </ol>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            5. {t('intellectual_property')}
          </div>
          <ol className="mt-4">
            <li className="ml-8">
              a. {t('all_intellectual_property_rights_relating')}
            </li>
            <li className="ml-8">
              b. {t('you_expressly_acknowledge')}
            </li>
            <li className="ml-8">
              c. {t('we_authorize_you_to_make_physical')}
            </li>
            <li className="ml-8">
              d. {t('you_agree_not_to_use_all')}
            </li>
            <li className="ml-8">
              e. {t('subject_to_these_general_conditions')}
            </li>
          </ol>
        </div>
        
        <div className="ml-8 my-8">
          <div className="font-bold">
            6. {t('site_content')}
          </div>
          <ul className="mt-4" type="a">
            <li className="ml-8">
              a. {t('the_information_and_content_available')}
            </li>
            <li className="ml-8">
              b. {t('we_make_our_best_efforts')}
            </li>
          </ul>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            7. {t('responsibility')}
          </div>
          <ol className="mt-4" type="a">
            <li className="ml-8">
              a. {t('we_cannot_under_any_circumstances')}
            </li>
            <li className="ml-8">
              b. {t('we_will_not_be_liable_for_any_loss')}
            </li>
            <li className="ml-8">
              c. {t('we_cannot_be_held_responsible')}
            </li>
          </ol>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            8. {t('site_security')}
          </div>
          <ol className="mt-4" type="a">
            <li className="ml-8">
              a. {t('we_make_no_warranty')}
            </li>
            <li className="ml-8">
              b. {t('you_further_agree_not_to')}
            </li>
          </ol>
        </div>
        
        <div className="ml-8 my-8">
          <div className="font-bold">
            9. {t('hyperlinks')}
          </div>
          <ol className="mt-4" type="a">
            <li className="ml-8">
              a. {t('we_expressly_authorize')}
            </li>
            <li className="ml-8">
              b. {t('you_agree_not_to_create_a_hypertext')}
            </li>
            <li className="ml-8">
              c. {t('we_reserve_the_right_to_withdraw')}
            </li>
            <li className="ml-8">
              d. {t('our_site_contains_links_to_sites')}
            </li> 
          </ol>
        </div>

        <div className="ml-8 my-8">
          <div className="font-bold">
            10. {t('applicable_right')}
          </div>
          <ol className="mt-4" type="a">
            <li className="ml-8">
              a. {t('these_general_conditions_of_use')}
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default GeneralTerms;
