import * as React from "react";

import Layout from "../components/layout/layout";
import GeneralTerms from "../components/privacy_policies/general_terms";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const PolitiqueDeCookies = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={t('general_conditions_of_use')}
        description={t('general_conditions_of_use')}
      />
      <Layout>
        <GeneralTerms />
      </Layout>
    </>
  )
}

export default PolitiqueDeCookies;
